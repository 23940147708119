import axios, { AxiosError, AxiosInstance } from 'axios'
import { setCookie, removeCookie } from 'helpers/cookies'
import { getCountryId, getCountryKey, getLanguageId } from './country'
import { getPublicHeader, serviceType, getFetchUrl } from './constants'
import { message } from 'antd'
import { isServer } from './utilities'
import { languages } from './country.base'
import { captureException } from '@sentry/nextjs'

const log = process.env.NODE_ENV == 'development' ? console.log : () => void 0

function getHeader() {
  const header = {
    ...getPublicHeader()
  }
  return header
}

// Create Axios instance
function createAxiosInstance(baseURL, headers) {
  return axios.create({ baseURL, headers })
}

// Main function
function configuredAxios(serviceType: serviceType) {
  // Validate
  if (!serviceType) throw Error('serviceType is required')

  // Get header
  const header = getHeader()

  // Create and return axios
  const url = getFetchUrl(serviceType)
  return createAxiosInstance(url, header)
}

type LanguageItem = (typeof languages)[0]

const chain = (
  serviceType: serviceType,
  language?: Partial<LanguageItem> | undefined
): AxiosInstance => {
  let axios: AxiosInstance = null
  axios = configuredAxios(serviceType)

  // 添加请求拦截器
  axios.interceptors.request.use(async (config) => {
    const defaultHeaders = {}
    try {
      const hasHttp = /^https?:\/\//.test(config.baseURL)
      const _baseUrl =
        !hasHttp && !isServer() ? window.location.origin + '' + config.baseURL : config.baseURL
      const url = new URL(_baseUrl + '' + config.url)
      log(`%c[${new Date().toLocaleString()}]: axios request ${url.pathname}`, 'color:red;', config)
    } catch (error) { }
    let countryKey = undefined
    let languageCode = language?.id || ''
    if (typeof window === 'undefined') {
      const result = await import('next/headers').then((result) => {
        const headers = result.headers() as any
        const host = headers.get('host') || headers.get(':authority')
        // const cookieHeader = headers.get('cookie') || '';
        const cookies = result.cookies()
        const sessionId = cookies.get('sessionid')?.value // 解析cookie获取sessionid
        config.headers['Cookie'] = headers.headers?.cookie ?? ''
        const countryKey = getCountryKey(host)
        return { sessionId, countryKey }
      })
      countryKey = result.countryKey
    }

    if (!config.headers.hasOwnProperty('Country-Id')) {
      const countryId = getCountryId(countryKey)
      defaultHeaders['Country-Id'] = '' + countryId
    }

    if (!languageCode) {
      languageCode = getLanguageId()
    }

    defaultHeaders['Language-Id'] = '' + languageCode

    config.headers = {
      ...defaultHeaders,
      ...config.headers
    }

    return config
  })

  // 添加响应拦截器
  axios.interceptors.response.use(
    (response) => {
      try {
        const hasHttp = /^https?:\/\//.test(response.config.baseURL)
        const _baseUrl =
          !hasHttp && !isServer()
            ? window.location.origin + '' + response.config.baseURL
            : response.config.baseURL
        const url = new URL(_baseUrl + '' + response.config.url)
        log(
          `%c[${new Date().toLocaleString()}]: axios response ${url.pathname}`,
          'color: green;',
          response
        )
      } catch (error) {
        console.log('request error', error)
      }
      return response
    },
    async (error: AxiosError) => {
      // logError(error?.response?.data ?? error?.response ?? error)
      const originErrorResponse = error?.response
      const statusCode = error?.response?.status

      if (statusCode === 401) {
        setCookie('auth_status', 'expired')
        removeCookie('sessionid')
        // eslint-disable-next-line prefer-promise-reject-errors
        if (typeof window !== 'undefined') {
          window.location.href = '/get-started'
          return new Promise(() => { })
        } else {
          // const { cookies } = await import('next/headers')
          // cookies().delete('sessionid')
          // const { redirect } = await import('next/navigation')
          // redirect('/get-started')
          return Promise.resolve()
        }
        //
        // return Promise.reject([error, originErrorResponse])
      } else {
        // fix the problem of saga overwriting the original axios error
        // eslint-disable-next-line prefer-promise-reject-errors
        console.log(error, originErrorResponse)
        if (originErrorResponse?.data?.message && typeof window != 'undefined') {
          originErrorResponse?.data?.message && message?.error(originErrorResponse?.data?.message)
        }
        // if (statusCode >= 400 && statusCode !== 401) {
        if (statusCode >= 500) {
          captureException(error)
        }
        return Promise.reject(originErrorResponse)
      }
    }
  )
  return axios
}
export default chain

export async function getAiSummary({ job_preference_id, rewrite_type = '' }) {
  // 构建请求的Body
  const requestBody = {
    job_preference_id,
    rewrite_type,
    stream: true // 设置 stream 为 true，启动逐字输出
  }
  Object.keys(requestBody).map((key) => {
    if (!requestBody[key]) {
      delete requestBody[key]
    }
  })
  try {
    const header = {
      'Content-Type': 'application/json'
    }
    const url = getFetchUrl('base')
    const countryId = getCountryId()
    header['Country-Id'] = '' + countryId
    let languageCode = getLanguageId()
    header['Language-Id'] = '' + languageCode
    // 使用 fetch 发送 POST 请求，并设置 keepalive 确保连接稳定
    const response = await fetch(url + '/aicv/ai_writing/career_summary', {
      method: rewrite_type ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody),
      keepalive: true,
      credentials: 'include' // 自动发送和接收 cookies
    })
    if (!response.ok) {
      throw new Error('网络响应不正常')
    }
    return response.body.getReader() // 返回响应流的读取器
  } catch (error) {
    // 处理错误
    console.error('请求错误:', error)
    throw error
    // addMessageToChat("请求失败，请稍后重试。", 'bot');
  }
}

export async function markAiSummaryUsing() {
  const requestBody = {}

  try {
    const header = {
      'Content-Type': 'application/json'
    }
    const url = getFetchUrl('base')
    const countryId = getCountryId()
    header['Country-Id'] = '' + countryId
    let languageCode = getLanguageId()
    header['Language-Id'] = '' + languageCode
    // 使用 fetch 发送 POST 请求，并设置 keepalive 确保连接稳定
    return await fetch(url + '/aicv/ai_writing/career_summary', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody),
      credentials: 'include' // 自动发送和接收 cookies
    })
  } catch (error) {
    // 处理错误
    console.error('请求错误:', error)
    // addMessageToChat("请求失败，请稍后重试。", 'bot');
  }
}
